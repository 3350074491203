<template>
  <v-card>
    <v-card-title class="blue-grey lighten-4">
      <v-row no-gutters>
        <v-col :cols="10">
          <div class="title text-truncate" style="width: 100%;">{{ currentModel.name }}</div>
        </v-col>
        <v-col align-self="center">
          <v-icon color="blue" large>museum</v-icon>
        </v-col>
      </v-row>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <Label label="Site" :value="currentModel.siteName" single-line />
      <Label
        label="Date Range"
        :value="currentModel.startDate + ' - ' + currentModel.endDate"
        single-line
      />
      <Label label="Resource Type" :value="currentModel.resourceType" single-line />
      <Label
        label="Analysis"
        :value="currentModel.analysisType + ' -- ' + currentModel.analyticType"
        single-line
      />
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <can I="delete" a="analytics">
        <v-btn icon :to="`/analytics/models/${ this.currentModel.id }`">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn icon @click="deleteModel">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </can>
      <v-btn v-if="!$can('delete', 'analytics')" icon :to="`/analytics/models/${ this.currentModel.id }`">
        <v-icon>insert_chart</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Label from "@/modules/controls/_components/Label";

export default {
  components: {
    Label
  },
  props: {
    analyticModel: null
  },
  data() {
    return {
      currentModel: this.analyticModel
    };
  },
  computed: {},
  watch: {
    analyticModel: function(newVal) {
      this.currentModel = { ...newVal };
    }
  },
  methods: {
    async deleteModel() {
      this.$emit("onmodeldelete", this.currentModel);
    }
  }
};
</script>