<template>
  <section>
    <v-row>
      <v-col v-if="$can('delete', 'analytics')">
        <v-btn
          color="primary"
          :to="`/site/${this.selectedSite.siteId}/analytics/models/create`"
        >
          <v-icon left>mdi-plus-thick</v-icon>New Analytics Model
        </v-btn>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="auto" md="6" sm="6">
        <v-autocomplete
          :items="sites"
          :item-text="(item) => item.name"
          v-model="selectedSite"
          label="Select site"
          :return-object="true"
          @change="filterAnalyticModels"
          solo
        ></v-autocomplete>
      </v-col>
      <v-col cols="auto">
        <v-btn-toggle v-model="toggleView">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on">
                <v-icon>apps</v-icon>
              </v-btn>
            </template>
            <span>Show Card View of Saved Models</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on">
                <v-icon>list_alt</v-icon>
              </v-btn>
            </template>
            <span>Show List View of Saved Models</span>
          </v-tooltip>
          <can I="create" a="analytics">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on">
                  <v-icon>archive</v-icon>
                </v-btn>
              </template>
              <span>Show Archived Models</span>
            </v-tooltip>
          </can>
          <!-- <v-btn>
            <v-icon>archive</v-icon>
          </v-btn>-->
        </v-btn-toggle>
      </v-col>
    </v-row>
    <v-progress-linear
      :active="loading"
      :indeterminate="loading"
      absolute
      top
    ></v-progress-linear>
    <validation-summary :messages="server_errors" />
    <confirm-dialog
      ref="confirmDelete"
      title="Delete this model?"
    ></confirm-dialog>
    <confirm-dialog
      ref="confirmArchive"
      title="Archive selected models?"
    ></confirm-dialog>
    <confirm-dialog
      ref="confirmUnArchive"
      title="Un-Archive selected models?"
    ></confirm-dialog>

    <v-data-table
      :headers="headers"
      :items="mappedAnalyticModels"
      :show-select="$can('delete', 'analytics')"
      class="elevation-1"
      v-model="selectedMappedModels"
      v-if="toggleView === 1 && mappedAnalyticModels.length > 0"
    >
      <template v-slot:[`item.name`]="{ item }">
        <td>
          <router-link :to="`/analytics/models/${item.id}`">{{
            item.name
          }}</router-link>
        </td>
      </template>

      <template v-slot:top v-if="$can('delete', 'analytics')">
        <v-row class="ml-1">
          <v-spacer></v-spacer>
          <v-col cols="auto">
            <v-btn
              tooltip="Archive Selected Models"
              icon
              @click="handleModelArchiveMultiple"
              :disabled="selectedMappedModels.length === 0"
            >
              <v-icon>archive</v-icon>
            </v-btn>
            <v-btn
              tooltip="Delete Selected Models"
              icon
              @click="handleModelDeleteMultiple"
              :disabled="selectedMappedModels.length === 0"
            >
              <v-icon>mdi-delete-outline</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </v-data-table>

    <v-data-table
      :headers="headers"
      :items="mappedArchivedModels"
      show-select
      class="elevation-1"
      v-model="selectedMappedModels"
      v-if="toggleView === 2 && mappedArchivedModels.length > 0"
    >
      <!-- <template v-slot:item.name="{ item }">
        <td>
          <router-link :to="`/analytics/models/${item.id}`">{{ item.name }}</router-link>
        </td>
      </template>-->

      <template v-slot:top>
        <v-row class="ml-1">
          <v-spacer></v-spacer>
          <v-col cols="auto">
            <v-btn
              tooltip="Un-Archive Selected Models"
              icon
              @click="handleModelUnArchiveMultiple"
              :disabled="selectedMappedModels.length === 0"
            >
              <v-icon>unarchive</v-icon>
            </v-btn>
            <v-btn
              tooltip="Delete Selected Archived Models"
              icon
              @click="handleModelDeleteMultiple"
              :disabled="selectedMappedModels.length === 0"
            >
              <v-icon>mdi-delete-outline</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </v-data-table>

    <v-data-iterator
      :items="mappedAnalyticModels"
      v-if="toggleView === 0 && mappedAnalyticModels.length > 0"
    >
      <template>
        <v-row>
          <v-col
            v-for="model in mappedAnalyticModels"
            :key="model.id"
            cols="12"
            sm="12"
            md="6"
            lg="4"
            xl="3"
          >
            <model-grid-item
              :analyticModel="model"
              @onmodeldelete="handleModelDeleteFromChild"
            ></model-grid-item>
          </v-col>
        </v-row>
      </template>
    </v-data-iterator>

    <v-row v-if="mappedAnalyticModels.length === 0">
      <v-card
        class="card-center"
        flat
        tile
        style="background-color: #ecf2f6 !important; font-weight: 800"
      >
        No analytic models configured
      </v-card>
    </v-row>
  </section>
</template>

<style>
.card-center {
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  height: 25vh;
  width: 50%;
  margin-left: 25%;
}
</style>

<script>
import api from "../../analytics_models/_api";
import {
  getAnalyticModels,
  getSites,
  getPortfolioAnalyticModels,
} from "../../analytics_models/_api";
import ValidationSummary from "@/components/Dialogs/ValidationSummary";
import ConfirmDialog from "@/components/Dialogs/ConfirmDialog";
import ModelGridItem from "./ModelGridItem";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "AnalyticsModule",

  components: {
    "validation-summary": ValidationSummary,
    "confirm-dialog": ConfirmDialog,
    "model-grid-item": ModelGridItem,
  },

  async created() {
    await this.getUserSites();

    if (this.defaultSite !== null) {
      this.getModels();
      this.loading = false;
    }
  },

 
  data() {
    return {
      headers: [
        { text: "Model Name", value: "name" },
        { text: "Site Name", value: "siteName" },
        { text: "Resource Type", value: "resourceType" },
        { text: "Analytic Type", value: "analyticType" },
        { text: "Analysis Type", value: "analysisType" },
        { text: "Start Date", value: "startDate" },
        { text: "End Date", value: "endDate" },
      ],
      server_errors: [],

      mappedAnalyticModels: [],
      mappedArchivedModels: [],

      analyticModels: [],

      selectedModels: [],

      selectedMappedModels: [],

      sites: [],
      selectedSite: {},
      siteOptions: [],
      toggleView: 0,
      loading: false,
    };
  },

  computed: {
    ...mapGetters({ defaultSite: "session/defaultSite" }),
  },

  methods: {
    ...mapActions({ setDefaultSite: "session/setDefaultSite" }),

    getAnalyticModels,
    getPortfolioAnalyticModels,
    getSites,

    getUserSites() {
      let self = this;
      this.getSites()
        .then((response) => {
          self.sites = response;
          //self.siteOptions = self.mapSiteLookup(response);
          //self.getModels();
        })
        .catch((error) => {
          self.server_errors = self.errorSummary(error);
        });
    },

    filterAnalyticModels(site) {
      this.setDefaultSite(site);
      this.getModels();
    },

    async getModels() {
      let self = this;
      if (this.defaultSite) {
        this.loading = true;
        self.selectedSite = self.defaultSite;
        self.analyticModels = await api.getAnalyticModels(self.selectedSite.siteId);
        self.mappedAnalyticModels = self.mapAnalyticModels(self.analyticModels);
        this.loading = false;
      }
    },

    mapAnalyticModels(amList) {
      let self = this;
      let resourceType = "";
      let getMappedModel = (item) => {
        if (item.endUses.length > 0) {
          resourceType = item.endUses[0].resourceType;
        }
        return {
          id: item.id,
          name: item.name,
          siteName: item.siteName,
          siteId: item.siteId,
          resourceType: resourceType,
          analyticType: item.analyticType.analyticTypeName,
          analysisType: item.analysisType.analysisTypeName,
          startDate: self.formatDate(item.startDateTime),
          endDate: self.formatDate(item.endDateTime),
        };
      };
      var at = amList.map(getMappedModel);
      return at;
    },

    mapSiteLookup(sites) {
      console.log("site lookup");
      console.log(sites);
      const makeOptionItems = (item) => {
        return {
          value: item.siteId,
          text: item.name,
        };
      };
      var sl = sites.map(makeOptionItems);
      return sl;
    },

    async processDeleteAnalyticModel() {
      let self = this;
      //TODO: siteid here is going to go away someday soon...
      const deleteModels = (modelId) =>
        this.axios
          .delete("/v1/AnalyticModel/" + modelId)
          .then((response) => {
            console.log(response);
          })
          .catch((error) => {
            console.log(error);
            self.errored = true;
          })
          .finally(() => (this.loading = false));

      var idsToDelete = this.selectedMappedModels.map((m) => {
        return m.id;
      });
      var deletePromises = idsToDelete.map(deleteModels);
      console.log(deletePromises);
      Promise.all(deletePromises)
        .then(() => {
          self.getModels();
        })
        .catch(() => {
          self.errored = true;
        })
        .finally(() => (this.loading = false));
    },

    async processArchiveAnalyticModel() {
      let filteredModels = this.mappedAnalyticModels.filter(
        (m) => !this.selectedMappedModels.includes(m)
      );
      this.mappedAnalyticModels = filteredModels;
      this.mappedArchivedModels = [...this.selectedMappedModels];
      this.selectedMappedModels = [];
    },

    async processUnArchiveAnalyticModel() {
      let filteredModels = this.mappedArchivedModels.filter(
        (m) => !this.selectedMappedModels.includes(m)
      );
      this.mappedArchivedModels = [...filteredModels];
      let restoredList = this.mappedAnalyticModels.concat(
        this.selectedMappedModels
      );
      console.log(restoredList);
      this.mappedAnalyticModels = [...restoredList];
      this.selectedMappedModels = [];
    },

    getSite(id) {
      let site = this.sites.find((s) => s.siteId === id);
      return site;
    },

    formatDate(date) {
      return new Date(date).toISOString().substring(0, 10);
    },

    async handleModelDeleteFromChild(modelToDelete) {
      const msg =
        "<span>Are you sure you want to delete the <b>" +
        modelToDelete.name +
        "</b> Analytic Model?  This cannot be undone.</span>";
      const confirmed = await this.$refs.confirmDelete.confirm(msg);

      if (!confirmed) {
        return;
      }
      this.selectedMappedModels = [modelToDelete];
      this.processDeleteAnalyticModel();
    },
    async handleModelDeleteMultiple() {
      const msg =
        "Are you sure you want to delete the selected Analytic Models?  This cannot be undone.";
      const confirmed = await this.$refs.confirmDelete.confirm(msg);

      if (!confirmed) {
        return;
      }

      this.processDeleteAnalyticModel();
    },
    async handleModelArchiveMultiple() {
      const msg =
        "Are you sure you want to archive the selected Analytic Models?  Archived models are not available for creating MandV reports and analyses.";
      const confirmed = await this.$refs.confirmArchive.confirm(msg);

      if (!confirmed) {
        return;
      }
      //fake grooming the list
      this.processArchiveAnalyticModel();
    },
    async handleModelUnArchiveMultiple() {
      const msg =
        "Are you sure you want to un-archive the selected Analytic Models?";
      const confirmed = await this.$refs.confirmUnArchive.confirm(msg);

      if (!confirmed) {
        return;
      }
      //fake grooming the list
      this.processUnArchiveAnalyticModel();
    },
    errorSummary(error) {
      return error.response
        ? error.response.data.Errors
          ? error.response.data.Errors
          : error.response.data.errors
          ? error.response.data.errors
          : [error]
        : [error];
    },
  },
};
</script>
